<template>
  <div>
    <br />
    <div class="col-sm-12 text-right">
      <h1>Aplicaciones</h1>
    </div>
    <br />
    <DataGridV3 :configuracion="dataGridConfig" :data="dataSourceGrid" :seleccionar="seleccionar" ref="gridAplicaciones"  @ready="cargarAcciones">
      <div class="row">
        <div class="col-sm-auto">
          <button class="btn btn-info" @click="cargar()"> <i class='fa fa-refresh'></i> Recargar</button>
        </div>
        <div class="col-sm-auto" v-if="$auth.can('bpb_apps_admin','aplicaciones_crear')">
          <button  class="btn btn-primary" @click="crear"><i class='fa fa-plus-circle'></i> Nuevo</button>
        </div>
      </div>

    </DataGridV3>

    <AplicacionForm crear="true" @close="calcelar_seleccion" v-if="openModalForm" :aplicacion_prop="itemSeleccionado" @update="cargar"></AplicacionForm>


    <Modal v-if="openModalEliminar" :options="{width: '30vw',type:'error'}" @close="openModalEliminar = false">
      <div class="title">Eliminar: {{ itemSeleccionado.nombre }} </div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            Eliminarás la aplicación  {{ itemSeleccionado.nombre }} <br/>¿Deseas continuar?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="row justify-content-end">
            <div class="col-auto"><button class="btn btn-primary" @click="eliminarAplicacion"><i class="fa fa-check-square mr-sm-1"></i>Continuar</button></div>
            <div class="col-auto"><button class="btn btn-outline-secondary" @click="calcelar_seleccion"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
          </div>
        </div>
      </div>
    </Modal>

    <Modal v-if="openModalEstatus" :options="{width: '30vw',type:'warning'}" @close="openModalEstatus = false">
      <div class="title h2 text-white">Editar estatus de la aplicación</div>
      <div class="body">
        <p class="text-center">
          Cambiarás el estatus de la aplicación {{ itemSeleccionado.nombre }} a <b>{{ nuevo_estatus }}</b>
          <br/>¿Deseas continuar?
        </p>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="editarEstatus"><i class="fa fa-check-square mr-sm-1"></i>Continuar</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="calcelar_seleccion"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>

  </div>
</template>

<script>
import DataGridV3 from '@/components/DataGridV3.vue'
import Modal from '@/components/Modal'
import api from '@/apps/bpb_apps_admin/api/aplicaciones.api'
import AplicacionForm from "@/apps/bpb_apps_admin/components/AplicacionForm.vue";

export default {
  name: "Aplicaciones",
  data(){
    return {
      openModalForm: false,
      openModalEliminar: false,
      openModalEstatus: false,
      accionModal: 'crear',
      dataGridConfig: {
        name: 'aplicaciones',
        cols: {
          acciones: '',
          estatus: 'Estatus',
          id: 'ID',
          nombre: 'Nombre',
          descripcion: 'Descripción'
        }
        ,filters: {
          cols: {
            id: 'ID',
            nombre: 'Nombre'
          }
        }
        ,paginator: {
          pagina_actual: 1
          ,total_registros: 1
          ,registros_por_pagina: 20
        }
        ,mutators: {
          estatus: function (val,row,vue){
            let attr_checked = !row.estatus_activo ? "" : " checked ";
            return "<div class='custom-control custom-switch'>"+
                "<input data-action='pre_editar_estatus'"+attr_checked+"data-opcion='"+vue.$helper.toJson(row)+"' type='checkbox' class='switch-estatus custom-control-input' id='inputEstatus-"+row.id+"'>" +
                "<label class='custom-control-label' for='inputEstatus-"+row.id+"'>"+row.estatus+"</label" +
                "</div>"

          },
          acciones: function(val,row,vue) {
            let acciones = "<div class='text-center'>";
            if (vue.$auth.can('bpb_apps_admin','aplicaciones_editar'))
              acciones += "<i data-action='editar' data-opcion='"+vue.$helper.toJson(row)+"' class='btn-sm btn-editar rounded-circle btn-acciones-aplicacion fa fa-edit'></i>";

            return acciones+"</div>";
          },
        },
        show_search: true,
        search: ['nombre'],
        selector: false,
      },
      seleccionar: false,
      dataSourceGrid: [],
      aplicacion: null,
      accionesCargadas: false,
      itemSeleccionado: {
        id: null,
        nombre: null,
        descripcion: null,
        estatus: '',
        estatus_activo: false,
      }
    }

  },
  components: {
    DataGridV3,
    Modal, AplicacionForm
  },
  computed: {
    nuevo_estatus(){
      return  this.itemSeleccionado.estatus_activo ? 'Inactivo' : 'Activo'
    }

  },
  mounted() {
    this.cargar()
  },
  methods: {
    cargarAcciones() {
        let btns = document.querySelectorAll('.btn-acciones-aplicacion');
        btns.forEach(btn => {
          btn.removeEventListener('click', (e) => {
            this.ejecutarAccionBtn(e)
          }, false)

          btn.addEventListener('click', (e) => {
            this.ejecutarAccionBtn(e)
          }, false)
        })

      let switchs_estatus = document.querySelectorAll('.switch-estatus');
      switchs_estatus.forEach(btn => {
        btn.removeEventListener('change', (e) => {
          this.ejecutarAccionBtn(e)
        }, false)

        btn.addEventListener('change', (e) => {
          this.ejecutarAccionBtn(e)
        }, false)
      })
    },
    ejecutarAccionBtn(e){
      let seleccion  = this.$helper.fromJson(e.target.dataset.opcion);
      if (seleccion.id !== this.itemSeleccionado.id) {
        let accion = e.target.dataset.action;
        this.itemSeleccionado = {...seleccion}
        this[accion](seleccion);
      }
    }
    ,calcelar_seleccion(){
      this.openModalForm = false;
      this.openModalEliminar = false;

      if (this.openModalEstatus) {
        this.openModalEstatus = false;
        document.getElementById("inputEstatus-" + this.itemSeleccionado.id).checked = this.itemSeleccionado.estatus_activo;
      }

      this.itemSeleccionado = {
        id: null,
        nombre: null,
        descripcion: null,
        estatus: null,
        estatus_activo: false,
      };
    },
    crear(){
       this.calcelar_seleccion()
      this.openModalForm = true;
      this.accionModal = 'crear';
    },
    editar(){
      this.accionModal = 'editar';
      this.openModalForm = true;
    },
    pre_eliminar(){
        this.accionModal = 'eliminar';
        this.openModalEliminar = true;
    },

    pre_editar_estatus(){
      this.accionModal = 'pre_estatus';
      this.openModalEstatus = true;

    },

    async eliminarAplicacion(){
      this.openModalEliminar = false;
      await api.eliminar(this.itemSeleccionado.id, {estatus: this.nuevo_estatus}).then(() => {
        this.$helper.showMessage('Editar aplicación','Aplicación eliminada exitosamente.', 'success', 'alert')
        this.cargar()
        this.calcelar_seleccion()
      })
    },

    async editarEstatus(){
      this.openModalEstatus = false;
      await api.editar_estatus(this.itemSeleccionado.id, {estatus: this.nuevo_estatus.toLowerCase()}).then(() => {
        this.$helper.showMessage('Editar aplicación','Se cambió el estatus de la aplicación a <b>'+this.nuevo_estatus+'</b> ', 'success', 'alert')
        this.cargar()
        this.calcelar_seleccion()
      })
    },

    async cargar() {
        let dataSource = await api.index()
        console.log('datasource: ',dataSource);
        this.dataSourceGrid = dataSource.data.map(item => {
          let estatus_activo = (item.estatus.toLowerCase() === 'activo');
          return {...item, estatus_activo: estatus_activo}
        });
    },
  }
}
</script>
<style lang="css">
.btn-eliminar{
  background-color: #d7717a;
  color: white;
}
.btn-editar{
  background-color: #196eef;
  color: white;
}
.btn-eliminar:hover, .btn-editar:hover{
  cursor: pointer;
  filter: brightness(80%);
}
</style>

